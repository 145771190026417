<template>
  <div>
    <div class="contact-info" id="contact-info" data-aos="fade" data-aos-delay="0">

      <img src="@/projects/great-intersection/s1/img2.png" alt="img" class="img2" data-aos="zoom-in-left" />

  <svg viewBox="0 0 664 230" class="logo" xmlns="http://www.w3.org/2000/svg">
    <path d="M162.2,110.8c-2.7-6.3,0.2-8,3.6-1.8c9.4,13.3,15.6,21.3,26.4,33.2c7.4-10.5,19-25.2,25-43.5
    c2.9,0,25.5,16,25.5,19.5c0,2.5-5.5,3-7.2,3.2c-9.1,11.9-18,24.3-27.4,36.2c24.3,19.2,42,32.3,71.8,43.5c5.5,1.3,9.6,4.6,0.7,4.6
    c-9.8,0.5-18.5,7.1-25,13.5c-22.4-11-43.8-28.8-60.8-46.9c-26.3,21-55.4,39-88.9,45.8c-4.5-1.1,2.8-4,4.1-4.6
    c30.3-14.3,50.9-32.7,72-57.9C174.4,141.2,167.7,126.1,162.2,110.8 M264.8,42.1c3.6,1.1,30.4,20.3,15.9,21.8H120.9l-5,3l-9.1-13.3
    c3.8,2.4,137,1,145.5,1.4C252.5,54.7,262.4,42.1,264.8,42.1 M116.3,129.1c17.4-18.3,34.9-38.8,43.5-62.7c0-2.5,27.4,12.8,27.1,15.8
    c0,2.8-7.9,3.4-9.9,3.7C158.2,105.2,120.3,133.1,116.3,129.1z M185.5,28.1c1.1-6.7-6.6-19.3-9.6-22c8.7-2.6,20.7,5.7,27.4,11.9
    C229.1,43.9,183.9,68.9,185.5,28.1 M247,109.4c-4.5-11.3-12.6-21.3-22.3-28.6c-0.9-1.2-15.8-10.3-9.1-10.8
    c20.6,6.7,44.7,13.8,54.7,33.2C279,120.1,256.5,133.1,247,109.4z M309.4,81.7l-7.2-17.2c10.2,0,19.7-12.4,25.7-19.9
    c-7.3,0.5-13.4,3.7-20.4,5.3L302,35c13.6-2.6,22.3-17.7,24.2-30.5c1.4-3.6,17,11.7,16.8,13.3c0,2.1-5.5,2.3-7,2.5
    c-6.2,6.4-13.2,12.1-20.2,17.9c5.7,0.3,11.4,0.3,17.1,0c3.4-3,7.2-9.4,9.1-13.3c2.4-0.2,12.5,12.1,12.5,13.7c0,2.3-4.8,1.8-6.2,1.8
    c-10.8,8.9-21.6,18.1-31.9,27.5c7.5-0.5,14.9-0.7,22.3-0.9c0.8-5,1.7-10.6,0-15.6c0-0.7,0-1.4,1-1.4c0.7,0,14.2,5.7,15.6,6.6
    c5.4,2.5,1.9,4-1.2,6.4c-4.9,25.6-15.3,34.4-40.2,44.8c-12.6,5.3-21.1,4.7-10.5-0.6c15.5-7.7,28.6-18.4,34.1-35
    C329.4,75.1,316.9,78.3,309.4,81.7 M345.7,129.6h-15.4l-4.1,2.8l-8.6-12.4c10.2,2.2,55,1.2,66,1.4c-5.2-5.4-2.6-12.3-11.8-20.8
    c-1.9,1.4-3.8,2.3-5.8,3.2l-8.6-14.4c3.4-1.6,2.9-4.3,2.9-7.6V32.3c0-1.1,0-4.1-0.2-5.3c-0.7-1.8-2.4-3.9-2.4-5.7
    c0.1-3.9,19,6.5,20.9,6.9h17c0.5-0.7,4.8-7.5,6.5-7.5c2.9,0,12.2,7.8,14.4,9.4c5.1,2.8-1.4,5-3.6,7.1c0,10.5,0,21.3,1,31.8
    c-5.5,1.8-11.5,3.2-17.5,4.3l0.7-5.3h-21.6v18.5c5.8-1.1,11.3-2.3,17-3.4c-1.2-1.4-8.2-8-8.2-8.9c0-0.5,1.2-0.5,1.7-0.5
    c2.9,0,11.3,3,14.2,4.1c7.7,2.8,16.6,7.3,16.6,16.5c0,3.9-3.4,8.7-7.4,8.7c-7.6-0.4-9.2-10.6-13-15.3c-7.2,3.2-13.9,7.1-20.9,10.8
    c5.5,0.5,17.3,1.6,21.1,6.6c4.8,6,4.5,9.9,0.2,16.2H435c1.2-1.2,8.4-9.4,10.6-9.4c3.5,1.5,14.3,12.5,15.1,15.8
    c0,1.6-1.4,1.8-2.9,1.8h-36.5c5.4,3.3,17,9,3.4,10.8c-4.1,2.8-8.2,5.5-12.2,8.2h37.9c0.5-0.5,9.1-9.6,11.1-9.6c1.4,0,8,6.3,9,7.2
    c4.7,4.3,10.8,10.6,3.5,10.6c0,0-162.8-0.2-162.8-0.2l-4.1,3.2l-8.6-12.6c10.2,2.1,46.3,1.2,57.2,1.4
    C350.7,144.1,355.2,140.7,345.7,129.6z M354.6,210.9v5c-6.5,1.4-13,2.5-19.7,3.2c1.1-12.4,1.1-34.6,1-47.4c0.3-3.9-1-8.9-2.9-12.1
    c0.5-4.2,21.3,5.5,23.5,6.2h64.8c1.2-1.1,5.5-7.1,7.7-7.1c2.4,0,17.8,9.8,17.8,11.4c0,1.8-3.6,4.1-4.8,5.3c0,10.9-0.1,28.6,0.7,39.4
    c-6.7,1.6-13,3.2-19.9,3.9l0.5-7.8L354.6,210.9L354.6,210.9z M423.3,184.1v-10.8h-67.7l-1,2.1v8.5L423.3,184.1z M423.3,191.4h-68.7
    v11.9h68.7V191.4z M401.9,148.6c4.3-6.2,8.4-12.1,10.8-19H356c6.2,2.5,14.6,5.5,14.6,13.3c0,2.5-1,4.1-2.9,5.7H401.9z M385.7,24.1
    c-11.4,2.1-5.8-15.3-12.6-20.9c-8.4-7,10.9-2.4,17.5,5.6C397.3,16.8,394.5,22.5,385.7,24.1z M397.1,35.9h-19.9l-1.7,1.8v6.6h21.6
    V35.9z M375.5,60.4h21.6v-8.9h-21.6V60.4z M423.3,92c0,0,0-61.6,0-61.6c0-7-0.4-12-1.8-14.1C415,7,438.2,21,440.3,21.8h9.6
    c1-0.9,6-7.3,7.5-7.3c1.2,0,15.4,9.2,15.4,12.1s-5.3,4.4-7.5,5.5c-4.8,6.2-8.2,12.6-11.8,19.5c22.1,16.1,34.8,45.8-0.2,54
    c-1.4-12.2-6.7-11.6-13-17.2c0-1.8,6-0.9,7.4-0.9c20.2-0.2,6.6-26.9-0.7-35.5c1.7-7.6,3.1-14.9,4.3-22.7h-11l-1.7,1.6
    c0.3,25.5-1.2,51.3,0.7,76.9c-5.5,1.8-11.3,3.4-16.8,4.8C422.8,105.8,423.3,98.9,423.3,92 M549.8,21.5c0-6.4-1.7-10.7-3.1-15.8
    c2.9-5.8,29.5,7.6,28.8,11.4c0,2.8-4.3,4.4-6.5,5.3v36.6l24.3-0.2V22.9c0-5.6-1.7-11.4-3.4-16.5c0.5-5.4,23.8,6,26.7,7.3
    c6.4,3.6-0.5,7.5-4.3,9.2v36h22.1c1-1.1,7-8.2,9.1-8.2c4,1.1,18.7,9.1,19.7,14c0,2.5-6,5.5-7.7,6.6c0.2,24-0.9,118.8,1.4,141
    c-7.2,2.1-14.6,3.2-22.1,4.1l0.7-17.6H525.1v12.4c-7.2,1.8-14.4,3.4-21.8,4.3c2.3-22,1.4-124.3,1.4-148.4c0-6.1-1.3-11.3-3.1-15.6
    c0-4.8,23.8,6.5,27.4,7.3h20.9L549.8,21.5z M549.8,120V66.8H528l-2.9,2.8V120L549.8,120L549.8,120z M549.8,128h-24.7v62.7h24.7V128z
    M593.3,66.8h-24.3v53.1h24.3V66.8z M593.3,128h-24.3v62.7h24.3V128z M635.6,66.8h-23.3v53.1h23.3V66.8z M635.6,128h-23.3v62.7h23.3
    V128z M81.9,55.3c-0.6,1.3-1.7,1.8-4,1.8h-9c4,4.6,8.6,8.3,13.2,10.7l-0.1,1c-3.1,0.9-5.5,4-6.6,8.9c-5.5-4.9-9.9-11.8-13.2-20.6
    h-16c-4.1,8.5-9.6,16.1-17.4,21.6v20.1c0,6.9-1.3,11.7-10,12.8c0-7.7-1.6-9.4-6.8-10v-1.4h7.7c0.9,0,1.1-0.4,1.1-1.5V72H13
    C12.2,85.8,9.6,100.3,2,111.7L0.8,111c7-25.3,4.8-63.5,4.8-93.8l9.4,4.3H20l3.2-5.1l8.6,7.5c-0.5,0.7-1.5,1.4-3,1.9v50.7
    c4.8-5.5,8.3-12,11.2-19.3H29.9l-0.4-2h11.2c1.6-4.4,2.9-9.1,3.9-13.8H32.2l-0.4-2.1H45c1.5-7.8,2.5-15.9,2.9-23.5l11.7,2.6
    c-0.2,1.2-0.9,2.2-3.3,2.3c-0.9,6.1-2,12.4-3.7,18.6h5.9c1.9-6.3,3.8-15,4.8-21.4l10.7,3.5c-0.3,1.1-1.2,1.6-3.2,1.5
    c-2.5,5.1-6.4,11.4-10.4,16.4h4.9l4.3-6.6l8.2,6.8c-0.7,1.4-1.8,1.8-4,1.8H59.5c1.9,5,4.6,9.8,7.7,13.8h2l4.3-7.1L81.9,55.3
    L81.9,55.3z M13.6,51.7c0,5.7,0,11.8-0.3,18.2h7.6V47.4h-7.2V51.7z M20.8,23.5h-7.2v21.8h7.2V23.5z M62.1,76.6l3.5-5.1l8.8,7.9
    c-0.5,0.8-1.5,1.4-3.2,1.7c-1.6,21.3-0.1,30.1-14.3,30.6c0-6.2-1.4-7.6-7.2-9.2l0.1-1.4c2.1,0.1,6.5,0.2,8,0.2
    c1.2,0,1.9-0.1,2.4-0.6c1.4-1.4,2.2-8.4,2.7-21.9h-9.2c-2.9,13.8-9.3,25.9-22.8,33l-0.8-1.6c9.2-7.2,13.5-18.7,15.3-31.4H36
    l-0.5-2.1h10.2c0.6-4.7,0.8-9.6,0.8-14.4L59,63.7c-0.2,1.5-1.1,2.2-3.5,2.3c-0.2,3.6-0.8,7.1-1.4,10.6L62.1,76.6L62.1,76.6z
    M33.7,18.2c18.5,8.3,9.2,25.4,3,16.8c0.2-5.2-1.5-12.1-3.8-16.2L33.7,18.2L33.7,18.2z M52.1,41.3c-1.3,4.7-2.9,9.4-4.9,13.8h14.4
    c-1.5-4.2-2.7-8.9-3.7-13.8C57.8,41.3,52.1,41.3,52.1,41.3z M66.2,196.8H16.7C16,208.1,13.1,220.6,2,229.4l-0.8-1
    c9.8-17.9,6.3-43,7-62.8l1.4,0.6v-21.5l8.5,4.2h10.6l2.8-4.5l8.5,6.8c-0.4,0.6-1.4,1.3-2.6,1.7v10.9c0,0.2-2.3,2.6-8,2.6v-15.4H17.7
    v12.6c0,1-2.9,3.2-6.5,3.4l7.2,3.2h19.8v-10l7.6,0.9v-16.3l8.4,4.1h10.4l3.2-4.9l9,7.4c-0.5,0.7-1.6,1.4-3.1,1.9v10.6
    c0,0,0,0.1-0.1,0.2l7.1,6.4c-0.6,1.3-1.7,1.8-4.1,1.8h-18c-0.1,1-0.8,1.7-2.8,2v8h9.6l3.2-5.1l9.3,7.6c-0.5,0.7-1.6,1.5-3.1,2v10.2
    c0,0.1-2.4,2.9-8.6,2.9L66.2,196.8L66.2,196.8z M40.5,140.4c-0.6,1.3-1.7,1.9-3.9,1.9H6l-0.4-2.1h22.6l4.3-6.8L40.5,140.4
    L40.5,140.4z M31.6,209.8v7.8c5.4-0.3,11.5-0.6,17.6-1v1.4c-6,2.6-14.8,5.6-27.8,9.7c-0.6,1.3-1.4,2.1-2.4,2.3l-4.2-11.7
    c2,0,4.9-0.1,8.4-0.2v-20.2l11.3,1.2c-0.2,1.2-0.9,1.8-2.9,2.1v6.6h5.2l3.8-6.7l7.3,6.9c-0.6,1.3-1.8,1.9-3.9,1.9
    C44,209.8,31.6,209.8,31.6,209.8z M17,184.2c0,3.4,0,7.1-0.1,10.6h11.4v-10.6H17L17,184.2z M28.3,172.1H17v10h11.3V172.1z
    M19.3,153.3c14.5,2.4,7.4,15.2,2.4,9.1c-0.2-2.9-1.4-6.5-3.1-8.3L19.3,153.3L19.3,153.3z M47.4,182.1v-10h-8
    c-0.2,1.1-0.8,1.5-2.8,2v8C36.6,182.1,47.4,182.1,47.4,182.1z M36.6,194.8h10.8v-10.6H36.6V194.8z M78.5,140.4
    c-0.7,1.3-1.8,1.8-4,1.8H43l-0.4-2.1h23.4l4.3-7.1L78.5,140.4L78.5,140.4z M53.9,163.4c0,0.8-3.2,3.3-6.8,3.3v3.4h20l2.6-4.3
    c-1.7,0.5-2.2,0.5-4.2,0.5v-15.4H53.9V163.4z M78.2,211.5l0.2,7.1c3.8,1.5,8.7,9.1-12.3,9.1c-16.5,0-16.5-2.2-16.5-10.7V198l11.2,1
    c-0.2,1.1-0.6,1.8-2.7,2.1v8c4.7-2.8,10-6.7,12.8-9.5L79,207c-0.7,1.3-1.9,1.2-3.2,0.6c-4.4,1.8-11.6,3.4-17.8,4.2v5.2
    c0,2.2,0.1,2.3,2.3,2.3h10.8c3.2,0,3.4-0.6,6.3-7.8L78.2,211.5L78.2,211.5z M55.8,153.1c15.2,2,8.2,15.8,2.8,9.6
    c-0.2-3.1-1.7-6.7-3.4-8.8L55.8,153.1L55.8,153.1z M66.2,184.2H55.8v10.6h10.4V184.2z"/>
  </svg>
   <!--   <div class="logobg1"></div>
      <div class="logobg2">
      <img src="@/projects/great-intersection/s1/logobg1.png" :alt="`${info.caseName}_面水岸`" />
      <img src="@/projects/great-intersection/s1/logobg2.png" :alt="`${info.caseName}_近捷運`" />
      <img src="@/projects/great-intersection/s1/logobg3.png" :alt="`${info.caseName}_鄰公園`" />
      <img src="@/projects/great-intersection/s1/logobg1.png" :alt="`${info.caseName}_蘆洲站5分鐘`" />
      <img src="@/projects/great-intersection/s1/logobg2.png" :alt="`${info.caseName}_888萬起`" />
      <img src="@/projects/great-intersection/s1/logobg3.png" :alt="`${info.caseName}_快樂王國`" />
      </div>

      <img v-if="!isMobile" class="logo" src="@/assets/img/contact-logo.png" :alt="`${info.caseName}contact-logo`" data-aos="zoom-in" />
      <img v-if="isMobile" class="logo" src="@/assets/img/contact-logo_m.png" :alt="`${info.caseName}contact-logo`" data-aos="zoom-in" />
-->      <div class="info">
        <div class="btn flex-c" @click="showCallDialog" data-aos="zoom-in" data-aos-delay="100" v-if="info.phone">
          <span class="flex-c">
            <font-awesome-icon icon="phone" />
            <span>{{info.phone}}</span>
          </span>
        </div>
        <div class="btn flex-c" @click="showMessengerDialog" data-aos="zoom-in" data-aos-delay="200">
          <span class="flex-c">
            <font-awesome-icon :icon="['fab', 'facebook-messenger']" /><span>FB 諮詢</span>
          </span>
        </div>
        <a class="btn flex-c btfanpage" :href="info.fbLink" target="_blank" data-aos="zoom-in" data-aos-delay="300">
          <span class="flex-c">
            <font-awesome-icon :icon="['fab', 'facebook-f']" /><span>前往粉絲專頁</span>
          </span>
        </a>
        <div class="clear"></div>
        <div class="address flex-c" data-aos="fade" data-aos-delay="400" v-if="info.address">{{info.address}}</div>
        <div class="google-btn flex-c" data-aos="fade" data-aos-delay="400" @click="showMapDialog" v-if="info.address">
          <span class="flex-c">
            <font-awesome-icon icon="map-marker-alt" /><span>導航 Google 地圖</span>
          </span>
        </div>
      </div>
    </div>
    <el-dialog title :visible.sync="isShowCallDialog" :width="isMobile ? '90%' : '500px'" :modal-append-to-body="false">
      <CallDialog :phone="info.phone" />
    </el-dialog>
    <el-dialog title :visible.sync="isShowMessengerDialog" :width="isMobile ? '90%' : '500px'" :modal-append-to-body="false">
      <MessengerDialog :messenger="info.fbMessage" />
    </el-dialog>
    <el-dialog title :visible.sync="isShowMapDialog" :width="isMobile ? '90%' : '500px'" :modal-append-to-body="false" >
      <MapDialog :link="info.googleLink" :address="info.address" />
    </el-dialog>
  </div>
</template>

<script>
import info from '@/info'
import { isMobile, isTablet } from '@/utils'
import CallDialog from '@/components/Dialog/Call'
import MessengerDialog from '@/components/Dialog/Messenger'
import MapDialog from '@/components/Dialog/Map'
export default {
  name: 'contactInfo',
  components: {
    CallDialog,
    MessengerDialog,
    MapDialog,
  },
  data() {
    return {
      info,

      isMobile,
      isTablet,
      isShowCallDialog: false,
      isShowMessengerDialog: false,
      isShowMapDialog: false,
    }
  },
  methods: {
    showCallDialog() {
      // if (!this.isMobile) return
      this.isShowCallDialog = true
    },
    showMessengerDialog() {
      // if (!this.isMobile) return
      this.isShowMessengerDialog = true
    },

    showMapDialog() {
      // if (!this.isMobile) return
      this.isShowMapDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variableColor.scss';
@import '@/assets/style/variableDefault.scss';
.clear{clear: both;}

.contact-info {
 // background: $contact_bg;
  // background-image: url('../assets/img/contact_bg.jpg');
 // background-size: cover;
  // background: linear-gradient(
  //   to bottom,
  //   rgba(243, 149, 1, 1) 0%,
  //   rgba(218, 71, 19, 1) 100%
  // );
  //box-shadow: $contact_shadow;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  //width: 1200px;
  width: 60%;
 // height: 600px;
  /* background-size: 100vw auto;
  background-attachment: fixed;
  background-position: 0% 50%; */
  transform: translateY(0);
  margin:0 auto 2vw;
  padding:2vw 0 2vw;

     border: 2px solid transparent;
     border-image: linear-gradient(135deg, #db9d88 0%,#e2bda9 4%,#da927b 15%,#824b40 34%,#da927b 61%,#e2bda9 71%,#da927b 81%,#824b40 100%);
	  border-image-slice: 1;
  .logo{
    width: $contact_logo_pc_width;
    fill: #FFF;
    margin:$contact_logo_pc_margin;
  }
}

/*

.logo {
  position: relative;
  z-index: 2;
  width: $contact_logo_pc_width;
  height: auto;
  // height:;
  margin:$contact_logo_pc_margin;
}
.logobg{position: absolute;top: 0;left:calc(50% - 98.6vw * .5);width: 98.6vw;}
.logobg1{position: absolute;top: -1vw;left:calc(50% - 85vw * .5);width: 85vw;height: 25vw;
background: radial-gradient(ellipse at center,
 rgba(255,136,146,1) 0%,
 rgba(255,136,146,.5) 10%,
 rgba(252,103,108,0.3) 20%,
 rgba(216,73,134,0.1) 39%,
 rgba(128,0,197,0) 70%);
 animation: logobg1 1.5s ease-in-out infinite alternate;transform: scale(.8);

}
@keyframes logobg1 {
    20% {opacity: 1;transform: scale(.8);}
    100% {opacity: .6;transform: scale(1.1);}
}
.logobg2{position: absolute;top: 0;left:calc(50% - 98.6vw * .5);width: 98.6vw;opacity:0.5;
font-size: 20vw;
img{position: absolute;animation: op 2s ease-in-out infinite alternate;opacity: 1;
&:nth-child(1){top: 0.29em;left:0.06em;animation-delay: .6s;width:1.93em;}
&:nth-child(2){top: 0.26em;left:0.15em;animation-delay: .0s;width:1.75em;}
&:nth-child(3){top: 0.3em;left:0em;animation-delay: 1.2s;width:1.94em;}
&:nth-child(4){top: 0.29em;right:0em;animation-delay: .0s;width:1.93em;}
&:nth-child(5){top: 0.26em;right:0.1em;animation-delay: .8s;width:1.75em;}
&:nth-child(6){top: 0.3em;right:0.06em;animation-delay: 1.5s;width:1.94em;}
}
}
@keyframes op {
    50% {opacity: 1;}
    100% {opacity: 0;}
}

*/
.img2{position: absolute;
  right:size(-580);width: size(535);bottom:size(150);}
.info {
  width: 880px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}





$border-px:2px;
$border-radius:1.8em;
.btn {
  flex: 1;
  flex-basis: 11%;
  width: 280px;
  height: 60px;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 20px 10px;
  cursor: pointer;
  text-decoration: none;
  // border-radius: 60px / 60px;
  color: $contact_btn_color;
  background: $contact_btn_bg;
  box-shadow: $contact_btn_border;
  border-radius:$border-radius;
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  //font-weight: 900;
  &:first-child{margin-left:0 ;}
  &.half {
    width: 49%;
  }
  svg {
    color: $contact_btn_icon;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    transition: all 0.5s;
  }

  &:hover {
    background: $contact_btn_hover_bg;
    // background-image: url("../assets/img/contact_gold_button.png");
    color: $contact_btn_hover_color;
    box-shadow: none !important;

    svg {
      color: $contact_btn_hover_icon;
    }
  }
  &::before {
    content: '';
    width: 40%;
    height: 100%;
    display: block;
    background: #fff;
    position: absolute;
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    left: -10%;
    opacity: 0;
    top: 0;
    z-index: 5;
    transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
  }
  &:hover:before {
    opacity: 1;
    width: 90%;
    left: 140%;
  }
}
.address {
  width: calc(100% - 3px);
  height: 60px;
  font-size: 18px;
  font-weight: 700;
  background: #fff;
  border: 1px solid #0004;
  border-radius:1.8em;
  color: $contact_btn_border;
  font-weight: 700;
  box-shadow: $contact_btn_border;
  z-index: 1;
  padding: 0 280px 0 0;
  margin-right: -280px;
  // border-top-left-radius: 60px;
  // border-bottom-left-radius: 60px;
}
.google-btn {
  width: 280px;
  height: 60px;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  //font-weight: 900;
  // border-top-right-radius: 60px;
  // border-bottom-right-radius: 60px;
  color: $contact_google_btn_color;
  background: $contact_google_btn_bg;
  background-position: center !important;
  border: $contact_btn_border;
  border-radius:1.8em;
  transition: all 0.5s;
  z-index: 1;
  overflow: hidden;
  position: relative;

  svg {
    color: $contact_google_btn_icon;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    transition: all 0.5s;
  }
  &::before {
    content: '';
    width: 40%;
    height: 100%;
    display: block;
    background: #fff;
    position: absolute;
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    left: -10%;
    opacity: 0;
    top: 0;
    z-index: 5;
    transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
  }
  &:hover:before {
    opacity: 1;
    width: 90%;
    left: 140%;
  }

  &:hover {
    background: $contact_google_hover_btn_bg;
    color: $contact_google_hover_btn_color;

    svg {
      color: $contact_google_hover_btn_icon;
    }
  }
}

/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .contact-info {
    display: flex;
    width: 90% !important;
    height: 360px;
    padding: 60px 0 80px;

    .logo {
      width: $contact_logo_tablet_width;
    }
  }
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .contact-info {
   // background: $contact_bg;
    display: flex;
    width: 100%;
    //  max-width: 95%;
    height: auto;
    padding: 10% 8% 5vw 8%;
    transform: none;
    margin: 0;
     border: 0px solid transparent;

    .logo {
      width: $contact_logo_mobile_width;
      margin: 5% auto 10%;
    }
.logobg{position: absolute;top: 0;
left:calc(50% - 223vw * .5);width: 223vw;}
.logobg1{position: absolute;top: 0;
left:calc(50% - 123vw * .5);width: 123vw;height: 50vw;
}
.logobg2{left:calc(50% - 223vw * .5);width: 223vw;
font-size:51vw;
  }
  }

.btn {
 // flex: 1;
  flex-basis: 100%;
  margin: 0 0 20px 0;
    &.half {
      width: 280px;
    }
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(50% - 5em);
      margin-right: 0;
      + span {
        margin-left: 1.5em;
      }
    }
  }

  .info {
    width: 100%;
    justify-content: center;
    text-align: center;
    //margin-bottom: 40px;

    > * {
      margin-bottom: 12px;
      &.address {
        margin-bottom: 0;
      }
    }
  }

  .logo {
   // margin-bottom: 20px;
    margin: 0 auto 30px;
    left: auto;
    position: relative;
  }
  .btn,
  .address,
  .google-btn {
    width: 100%;
  }
  .google-btn {
     border-top-right-radius: 0px;
     border-top-left-radius: 0px;
    // border-bottom-right-radius: 30px;
    // border-bottom-left-radius: 30px;
  }
  .address {
    // width: 280px;
    padding: 0.8em 1em 3.5em 1em;
    text-align: justify;
    line-height: 1.5;
    height: auto;
    margin:0 0 0 0;
    // border-top-right-radius: 30px;
    // border-top-left-radius: 30px;
    // border-bottom-right-radius: 0px;
    // border-bottom-left-radius: 0px;
  }
  .google-btn {
    margin-top:-2.7em;
  }
}

@media only screen and (max-width: 321px) {
  .address {
    font-size: 14px;
  }
}
</style>
