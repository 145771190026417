import { isMobile } from '../utils/index'
export default {
   address: '台中市沙鹿區青年路永寧路口',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1819.0780511478913!2d120.554830658219!3d24.236318441888507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x899de0a84eac7bcd!2zMjTCsDE0JzEwLjciTiAxMjDCsDMzJzIxLjMiRQ!5e0!3m2!1szh-TW!2stw!4v1659516134992!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/dp6LhKL8rcT5EWP59',
   phone: '04-2663-1999',
  fbLink: 'https://www.facebook.com/103362852445712',
  fbMessage: 'https://m.me/103362852445712',
  caseName: '',
  indigatorLength: 10,
  caseId: 'great-intersection',

  houseInfos: [
   // ['投資興建', '龍瑩建設事業股份有限公司'],
   // ['規劃坪數', '16-50坪'],
   // ['基地面績', '約239.71坪'],
   // ['房型規劃', '1~3房'],
   // ['相關連結', "<a href='https://wl.debang.tw/'>快樂王國</a>"],
  ],
  gtmCode: [''], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
   // subTitle: '若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！
   room_type: ['兩房','三房','四房'],//房型
  }
}