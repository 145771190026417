export default [
  /* */
  { name: '勝麗交響曲', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '',offset:-60,mobileOffset:-75 , type: '' },
  { name: '城市遠見', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '',offset:-60,mobileOffset:-75 , type: '' },
  { name: '海線都心', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '',offset:-60,mobileOffset:-80,  type: '' },
  { name: '富域金流', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',offset:-60,mobileOffset:-80, type: '' },
  { name: '重劃指標', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:-60,mobileOffset:-80 , type: '' },
  { name: '六大優勢', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',offset:-60,mobileOffset:-80 , type: '' },
  { name: '海線旗艦', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:-60,mobileOffset:-80 , type: '' },
 // { name: '樣品屋', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:-60,mobileOffset:-80 , type: '' },
 // { name: '影片', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:-60,mobileOffset:-80 , type: '' },
  //{ name: '嚴選建材', imgSrc: '', subTitle: '', section: 'section10', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  // { name: '樣品屋預留', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '',offset:-60, mobileOffset:0 , type: '' },
  
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:-60,mobileOffset:-60, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:-60, mobileOffset:-60, type: 'btn' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:-60, mobileOffset:0, type: 'btn' },
]